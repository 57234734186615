<template>
  <div>
    <div class="search-bar">
      <a-form-model 
          ref="searchRef" 
          :model="searchForm"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol">
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="name" label="收款财务">
              <a-select placeholder="请选择财务账户"
                  v-model="searchForm.financial_account_id"
                  @change="handlerSearch">
                <a-select-option value
                  v-if="!isFinanceManage">不限</a-select-option>
                <a-select-option
                  v-for="(item, index) in manageFinanceList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="9">
            <a-form-model-item prop="pay_time" label="购买时间">
              <a-range-picker valueFormat="YYYY-MM-DD" style="width:100%;"
                  v-model="pay_time" @change="handlerSearch" />
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item prop="name" label="状态">
              <a-select placeholder="选择订单状态"
                  style="width:100%;"
                  v-model="searchForm.order_status"
                  @change="handlerSearch">
                <!-- <a-select-option value>全部</a-select-option> -->
                <a-select-option 
                    v-for="id in Object.keys(giftOrderStatus)"
                    :value="id" :key="id"
                    >{{ giftOrderStatus[id] }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="flow_no" label="购买流水号">
              <a-input allowClear v-model="flow_no"
                       enter-button
                       placeholder="订单编号 / 微信流水号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="9">
            <a-form-model-item prop="refund_time" label="退款时间">
              <a-range-picker valueFormat="YYYY-MM-DD" style="width:100%;"
                  v-model="refund_time" @change="handlerSearch" />
            </a-form-model-item>
          </a-col>
          <a-col :span="7">
            <a-form-model-item :wrapperCol="{ span: 24 }">
              <div style="width:100%; text-align:right;">
                <a-button @click="handlerResetForm"
                          class="mr-4">重置</a-button>
                <a-button type="primary"
                          html-type="submit"
                          @click="handlerSearch">搜索</a-button>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <div>
      <div class="flex justify-between">
        <span class="text-lg font-bold p-3">礼品卡订单</span>
      </div>
    </div>

    <base-table rowKey="id"
                :columnsData="columns"
                :tableData="tableData"
                :getListFunc="initData"
                :total="total"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #invoice_apply_id="{record}">
        <a-button v-if="record.invoice_apply_id" type="link" class="p-0" 
            @click="viewInvoice(record.invoice_apply_id)">查看</a-button>
        <span v-else>——</span>
      </template>
      <template #operation="{record}">
        <a-button type="link" class="p-0" 
            @click="handlerMember(record.add_order_user_id)">购买人</a-button>
        <a-button type="link" class="ml-2 p-0" 
            v-if="record.receive_user_id"
            @click="handlerMember(record.receive_user_id)">领取人</a-button>
      </template>
    </base-table>
    <!-- 发票详情 -->
    <InvoiceDetail
      v-if="isShowInvoiceDetail"
      :show.sync="isShowInvoiceDetail"
      :requestID="transformDetailID"
    />
  </div>
</template>

<script>
import pageData from "./columns"
import { getGiftCardOrderList } from "@/api/wallet.js"
import InvoiceDetail from "../../invoice/gift-invoice/components/detail.vue"
import { 
  isFinanceManage, 
  getFinanceList
} from "@/utils/authData.js"

export default {
  components: { InvoiceDetail },
  data () {
    return {
      ...pageData,

      isFinanceManage: isFinanceManage(),
      manageFinanceList: [],

      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      flow_no: "",
      pay_time: [],
      receive_time: [],
      refund_time: [],
      searchForm: {
        financial_account_id: undefined,
        order_status: "1",
        // add_time_start: "",
        // add_time_end: "",
        pay_time_start: "",
        pay_time_end: "",
        receive_time_start: "",
        receive_time_end: "",
        refund_time_start: "",
        refund_time_end: "",
        page: 1,
        page_count: 20,
      },
      total: 0,
      tableData: [],
      
      isShowInvoiceDetail: false,
      transformDetailID: '',
    }
  },
  async mounted () {

    this.manageFinanceList = await getFinanceList()

    // 如果是城市市场账号
    if(this.isFinanceManage) {
      if(this.manageFinanceList.length == 1){
        this.searchForm.financial_account_id = this.manageFinanceList[0].id
      }
    }

    if(this.isFinanceManage){
      if(this.searchForm.financial_account_id){
        this.handlerSearch()
      }
    }else{
      this.handlerSearch()
    }
  },
  methods: {
    viewInvoice(invoice_apply_id){
      this.transformDetailID = invoice_apply_id
      this.isShowInvoiceDetail = true
    },
    handlerSearch () {
      if(this.isFinanceManage){
        if(!this.searchForm.financial_account_id){
          this.$message.info("请选择财务账户")
          return
        }
      }

      this.searchForm.order_no = ""
      this.searchForm.pay_serial_no = ""
      this.flow_no = String(this.flow_no || "").replace(/^\s*/g, "").replace(/\s*$/g, "")
      if(this.flow_no){
        if(this.flow_no.length == 30){
          this.searchForm.order_no = this.flow_no
        }else{
          this.searchForm.pay_serial_no = this.flow_no
        }
      }

      this.searchForm.page = 1
      this.initData()
    },

    async initData () {
      if(this.isFinanceManage){
        if(!this.searchForm.financial_account_id){
          this.$message.info("请选择财务账户")
          return
        }
      }

      const params = this.searchForm

      if (this.pay_time.length == 2) {
        params.pay_time_start = this.pay_time[0] + " 00:00:00"
        params.pay_time_end   = this.pay_time[1] + " 23:59:59"
      }else{
        params.pay_time_start = ""
        params.pay_time_end   = ""
      }
      if (this.receive_time.length == 2) {
        params.receive_time_start = this.receive_time[0] + " 00:00:00"
        params.receive_time_end   = this.receive_time[1] + " 23:59:59"
      }else{
        params.receive_time_start = ""
        params.receive_time_end   = ""
      }
      if (this.refund_time.length == 2) {
        params.refund_time_start = this.refund_time[0] + " 00:00:00"
        params.refund_time_end   = this.refund_time[1] + " 23:59:59"
      }else{
        params.refund_time_start = ""
        params.refund_time_end   = ""
      }
      

      const { data, code } = await getGiftCardOrderList(params)
      // debug
      // if(getGiftCardOrderList) console.log(params)
      // let code = 0
      // let data = { list: [{
      //   "id": 1,
      //   "order_no": 222,
      //   "order_status": 4,
      //   "add_time": "2022-02-11 19:00:03",
      //   "pay_time": "2022-02-11 19:00:03",
      //   "receive_time": "2022-02-11 19:00:03",
      //   "pay_serial_no": "345678i9orertgyhjkjhgfdfghjsadsdas",
      //   "financial_account_name": "北京好利来视频有限公司",
      //   "add_order_user_id": 20,
      //   "receive_user_id": 10,
      //   "status": -1
      // }]}

      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }

    },
    
    // 重置
    handlerResetForm () {
      this.flow_no = ""
      this.pay_time = []
      this.receive_time = []
      this.refund_time = []
      this.searchForm = {
        financial_account_id: undefined,
        order_status: "1",
        // add_time_start: "",
        // add_time_end: "",
        pay_time_start: "",
        pay_time_end: "",
        receive_time_start: "",
        receive_time_end: "",
        refund_time_start: "",
        refund_time_end: "",
        page: 1,
        page_count: 20,
      }
      if(this.isFinanceManage){
        if(this.manageFinanceList.length == 1){
          this.searchForm.financial_account_id = this.manageFinanceList[0].id
          this.handlerSearch()
        }else{
          this.searchForm.financial_account_id = undefined
          this.tableData = []
          this.total = 0
        }
      }else{
        this.searchForm.financial_account_id = ""
        this.handlerSearch()
      }
    },
    
    handlerMember(memberId){
      const newPage = this.$router.resolve({
        path: "/member-detail",
        query: { member_id: memberId},
      })
      window.open(newPage.href, "_blank")
    }

  },
}
</script>

<style>
</style>