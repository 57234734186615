
export const columns = [
  {
    title: "收款财务账户",
    dataIndex: "financial_account_name",
    align: "center",
    width: "20%",
  },
  {
    title: "订单ID/流水号",
    dataIndex: "order_no",
    align: "center",
    width: "20%",
  },
  {
    title: "微信流水号",
    dataIndex: "pay_serial_no",
    align: "center",
    width: "20%",
  },
  {
    title: "金额",
    dataIndex: "order_amount",
    align: "center",
    width: 80,
  },
  {
    title: "购买时间",
    dataIndex: "pay_time",
    align: "center",
    width: "20%",
  },
  {
    title: "朋友领取时间",
    dataIndex: "receive_time",
    align: "center",
    width: "20%",
  },
  {
    title: "退款时间",
    dataIndex: "refund_time",
    align: "center",
    width: "20%",
  },
  {
    title: "状态",
    dataIndex: "order_status",
    align: "center",
    width: 80,
    slots: {
      customRender: "order_status",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGiftOrderStatus(val),
  },
  {
    title: "是否删除",
    dataIndex: "status",
    align: "center",
    width: 80,
    slots: {
      customRender: "status",
    },
    slotsType: "format",
    slotsFunc: (val) => {
      return val == -1 ? "已删除" : "——"
    },
  },
  {
    title: "发票",
    dataIndex: "invoice_apply_id",
    align: "center",
    width: 80,
    slots: { customRender: "invoice_apply_id" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 120,
    slots: { customRender: "operation" },
  },
];


// 订单状态
export const giftOrderStatus = {
  1: "全部已支付",
  3: "待领取",
  4: "已领取",
  5: "已退款",
  2: "未支付",
};

export function formatGiftOrderStatus(val) {
  return giftOrderStatus[val];
}

export default {
  columns,
  giftOrderStatus,
  formatGiftOrderStatus
}